<template>
  <section :class="$style.wrapper">
    <section
      v-show="isLoading || isAutoCompletedLoading"
      :class="$style.loadingContainer"
    >
      <div v-if="isAutoCompletedLoading">
        <div :class="$style.completedTips" v-if="isAutoCompleted">
          Verification Completed! <br />
          Continue to execute the trade...
        </div>
      </div>
      <a-spin>
        <a-icon slot="indicator" :component="IconWait" spin />
      </a-spin>
    </section>
    <RegisterHomeLayout v-show="!isLoading && kycStatus !== 'Rejected'">
      <template #left>
        <section :class="$style.stepList">
          <section :class="$style.stepHeader">
            <div :class="$style.title">FIAT TO CRYPTO</div>
            <div :class="$style.desc">Start on-ramp <span>in 1 min</span></div>
          </section>
          <section :class="$style.stepBody">
            <div :class="$style.stepBodyHeader">
              <div :class="$style.title">FINISH SETTING UP</div>
              <div :class="$style.count">{{ currentStep }}/{{ stepCount }}</div>
            </div>
            <div :class="$style.stepBodyContent">
              <template
                v-for="(item, index) in kycStepLists4IndividualAdditional"
              >
                <section
                  v-if="!item.hidden"
                  :key="index"
                  :class="$style.stepItem"
                  @click="onStepClick(item)"
                >
                  <div :class="$style.itemLeft">
                    <div
                      :class="{
                        [$style.itemAction]: true,
                        [$style.itemSel]: item.status === 'finish'
                      }"
                    >
                      <IconCheckWhite v-if="item.status === 'finish'" />
                    </div>
                  </div>

                  <div :class="$style.itemRight">
                    <div :class="$style.itemTitle">{{ item.title }}</div>
                    <div :class="$style.itemDesc">{{ item.desc }}</div>
                  </div>
                </section>
              </template>
            </div>
            <div :class="$style.stepBodyFooter">
              <IconCoffee :class="$style.iconCoffee" />
              <div :class="$style.tips">
                {{ $t("These steps are only required for the first trade.") }}
              </div>
            </div>
          </section>
        </section>
      </template>
      <template #default>
        <transition>
          <keep-alive>
            <router-view>
              <slot name="title" slot="title" />
            </router-view>
          </keep-alive>
        </transition>
      </template>
    </RegisterHomeLayout>
    <ModalBase
      :visible.sync="isBaseModalVisible"
      @cancel="onCancel"
      @ok="onOk"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Spin, Icon } from "ant-design-vue";
import emitter from "@/utils/eventBus";
import RegisterHomeLayout from "@/components/register/HomeLayout.vue";
import IconCheckWhite from "@/assets/img/common/icon-check-white.svg?inline";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";
import IconCoffee from "@/assets/img/register/icon-coffee.svg?inline";
import ModalBase from "@/components/modal/ModalBase.vue";
import { getAutocompleted } from "@/api/kyc";
import { checkProcess } from "@/api/v2/kyc";
import { displayErrors } from "@/utils";

export default {
  name: "AdditionalKyc",
  props: {},
  data() {
    return {
      IconWait,
      isLoading: false,
      isAutoCompletedLoading: false,
      isAutoCompleted: false,
      kycStatus: "",
      isBaseModalVisible: false
    };
  },
  computed: {
    ...mapState("KYC", ["kycStepLists4IndividualAdditional", "idvCompleted"]),
    stepCount() {
      const filterArr = this.kycStepLists4IndividualAdditional.filter(
        item => item.hidden === false
      );
      return filterArr.length;
    },
    currentStep() {
      const filterSteps = this.kycStepLists4IndividualAdditional.filter(
        item => item.status === "finish"
      );
      return filterSteps.length;
    }
  },
  components: {
    [Icon.name]: Icon,
    [Spin.name]: Spin,
    RegisterHomeLayout,
    IconCheckWhite,
    ModalBase,
    IconCoffee
  },
  methods: {
    ...mapActions(["updateModalView"]),
    ...mapActions("KYC", [
      "setIdvCompleted",
      "changeStepHiddenStatus",
      "changeCurrentStep4IndividualAdditional"
    ]),
    onCancel() {
      this.isBaseModalVisible = false;
    },
    onOk() {
      this.isBaseModalVisible = false;
    },
    onStepClick(item) {
      const { status } = item;
      const { path } = this.$route;
      if (status !== "finish") return; // 没有finish的不能点击
      if (
        [
          "/additional/individual/autoVerify",
          "/additional/individual/verifySuccess",
          "/additional/individual/verifyFailed"
        ].includes(path) // 自动审核后面的页面都不可以点击
      )
        return;

      const toIndex = this.$router.history.stack.findIndex(
        history => history.name === item.name
      );

      const currentIndex = this.$router.history.stack.findIndex(
        history => history.name === this.$router.currentRoute.name
      );
      this.$router.go(toIndex - currentIndex);
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const result = await checkProcess();
        if (result.status === "success") {
          const { fields } = result.data[0];
          const idv_completed = !fields.includes("veriff_id"); // 存在veriff_id 说明需要做人脸识别
          this.setIdvCompleted(idv_completed);
          this.changeStepHiddenStatus(idv_completed);

          if (fields.includes("email_verified")) {
            this.$router.push({ name: "additional.individual.enterEmail" });
          } else {
            this.$router.push({ name: "additional.individual.personalInfo" });
          }
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.isLoading = false;
      }
    },
    async checkAutocompleted() {
      this.isAutoCompletedLoading = true;

      try {
        this.isAutoCompletedLoading = true;
        const { data, success } = await getAutocompleted();
        if (success) {
          const { auto_completed, kyc_status } = data;
          this.isAutoCompleted = auto_completed;
          this.kycStatus = kyc_status;
          if (auto_completed) {
            emitter.emit("legend:kyc-manual", { status: kyc_status });
            this.changeCurrentStep4IndividualAdditional("0");
            this.changeCurrentStep4IndividualAdditional("1");
            this.changeCurrentStep4IndividualAdditional("2");
            this.changeCurrentStep4IndividualAdditional("3");
            this.$router.push({ name: "additional.individual.verifySuccess" });
          } else {
            if (kyc_status === "Rejected") {
              emitter.emit("legend:kyc-manual", { status: kyc_status });
              this.updateModalView("RejectedAccount");
              this.isBaseModalVisible = true;
              return;
            } else {
              this.fetchData();
            }
          }
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.isAutoCompletedLoading = false;
      }
    }
  },
  created() {
    this.checkAutocompleted();
  },
  mounted() {}
};
</script>
<style module lang="scss">
@import "~rfs/scss";

.wrapper {
  .completedTips {
    @include rfs(20px);
    @include rfs(4.5rem, margin-bottom);

    color: var(--grey-2);
    text-align: center;

    animation: scroll 1s linear;
  }

  @keyframes scroll {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .loadingContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    :global {
      .ant-spin-dot {
        @include rfs(4.5rem);

        text-align: center;
      }
    }
  }

  .stepList {
    position: absolute;
    left: 4.375rem;
    top: 3.75rem;
    min-width: rfs-value(280px);
    border-radius: rfs-value(10px);

    .stepHeader {
      @include rfs(20px, margin-bottom);
      @include rfs(10px 0, padding);

      background-color: var(--legend-panel-bg);
      border-radius: rfs-value(10px);
      text-align: center;

      .title {
        @include rfs(22px);

        font-weight: bold;
        color: var(--legend-c-text);
      }
      .desc {
        @include rfs(16px);
        color: var(--legend-c-text);

        span {
          font-weight: bold;
          color: var(--primary);
        }
      }
    }
    .stepBody {
      @include rfs(10px 20px, padding);

      text-align: center;
      background-color: var(--legend-panel-bg);
      border-radius: rfs-value(10px);

      .stepBodyHeader {
        @include rfs(18px);
        @include rfs(20px, margin-bottom);

        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: var(--legend-c-text);

        .title {
        }
        .count {
          @include rfs(36px, margin-left);
        }
      }
      .stepBodyContent {
        @include rfs(20px, margin-bottom);

        .stepItem {
          &:not(:last-child) {
            @include rfs(10px, margin-bottom);
          }

          @include rfs(10px 10px 20px 10px, padding);

          display: flex;
          border-radius: rfs-value(10px);
          background-color: rgba(237, 237, 244, 0.4);
          cursor: pointer;
          .itemLeft {
            @include rfs(15px, margin-right);

            .itemAction {
              @include rfs(24px, width);
              @include rfs(24px, height);

              background-color: var(--legend-component-bg);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .itemSel {
              background-color: var(--success);
            }
          }
          .itemRight {
            @include rfs(14px);

            text-align: left;
            .itemTitle {
              @include rfs(5px, margin-bottom);

              font-weight: bold;
              color: var(--legend-c-text);
            }
            .itemDesc {
              max-width: 180px;
              color: var(--legend-c-text);
            }
          }
        }
      }

      .stepBodyFooter {
        display: flex;
        align-items: center;
        text-align: left;

        .iconCoffee {
          width: 32px;
          margin-right: 0.9375rem;
        }

        .tips {
          max-width: 190px;
          color: var(--danger);
        }
      }
    }
  }
}
</style>
