<template>
  <section :class="$style.tradePaymentListWrapper">
    <template v-if="!isLoading">
      <TradeTabs :paymentList="paymentList" @change:tabsItem="changeTabsItem">
        <div v-if="dailyLimit" :class="$style.dailyLimit">
          {{ dailyLimit }}
        </div>
        <TradeTabPane>
          <template #list-header>
            <div :class="$style.listHeader">
              <div :class="$style.title">
                {{ $t(getListTitle) }}
              </div>
              <div
                v-if="showAddCardBtn"
                :class="$style.addCard"
                @click.stop="toAddCard"
              >
                {{ $t("ADD NEW CARD") }}
              </div>
              <div
                v-if="showAddMoney"
                :class="$style.addMoney"
                @click.stop="toDeposit"
              >
                + {{ $t("ADD MONEY") }}
              </div>
              <!-- <div
                v-else-if="showLinkAccountBtn"
                :class="$style.linkAccount"
                @click.stop="toLinkAccount"
              >
                {{ $t("LINK A BANK ACCOUNT") }}
              </div> -->
            </div>
          </template>
          <template #data-list>
            <TabPaneItem
              v-for="(item, index) in getTabPane"
              :key="index"
              :itemData="item"
              :isEnoughBalance="isEnoughBalance"
              @change:paneItem="changePaneItem"
            >
              <template #action="{itemData}" v-if="!allowAchPull">
                <template v-if="itemData.type === 'balance'">
                  <div
                    v-if="showBalanceAddMoney(itemData.type)"
                    :class="$style.addBalanceMoney"
                    @click.stop="toDeposit"
                  >
                    + {{ $t("Add money") }}
                  </div>
                </template>
              </template>
            </TabPaneItem>

            <div
              v-if="showMoreOrLess"
              :class="$style.moreOrLess"
              @click="handleMoreOrLess"
            >
              <span>{{ $t(`${getTxtMoreOrLess}`) }}</span>
              <IconArrow
                :class="[
                  getTxtMoreOrLess === 'Show All'
                    ? $style.iconArrowDown
                    : $style.iconArrowUp
                ]"
              />
            </div>
          </template>
          <template #credit_card_empty v-if="isCreditcardDataListEmpty">
            <div
              :class="[$style.creditCardEmpty, $style.listItem]"
              @click.stop="toAddCard"
            >
              + {{ $t("ADD CARD") }}
            </div>
          </template>
          <template #link_account_empty>
            <div v-if="allowPagsmile"></div>
            <div
              v-else-if="isAchPullAccountDataListEmpty"
              :class="[$style.creditCardEmpty, $style.listItem]"
              @click.stop="toLinkAccount"
            >
              + {{ $t("LINK A BANK ACCOUNT") }}
            </div>
            <div
              v-else-if="showUnLinkAccountBtn"
              :class="[$style.creditCardEmpty, $style.listItem]"
              @click.stop="toPaymentMethodsList"
            >
              {{ $t("Manage Your Payment Methods") }}
            </div>
          </template>
        </TradeTabPane>
      </TradeTabs>
    </template>
    <template v-else>
      <a-icon slot="indicator" :component="IconWait" spin />
    </template>
    <!-- 后面有需求了再放开 -->
    <div :class="$style.description" v-if="false">
      Can't find your preferred payment method?
      <span>Explore other options!</span>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { Spin, Icon } from "ant-design-vue";
import { commonMixin } from "@/mixin/commonMixin";
import { quoteMixin } from "@/mixin/quoteMixin";
import { checkAchPullProcess } from "@/api";
import { displayErrors, formatNumber } from "@/utils";
import TradeTabs from "./TradeTabs.vue";
import TradeTabPane from "./TradeTabPane.vue";
import TabPaneItem from "./TabPaneItem.vue";
import IconArrow from "@/assets/img/common/icon-arrow.svg?inline";
import IconWait from "@/assets/img/common/icon-wait.svg?inline";

export default {
  name: "TradePaymentList.Index",
  props: {
    isLoading: [Boolean],
    isEnoughBalance: [Boolean],
    paymentList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [commonMixin, quoteMixin],
  data() {
    return {
      listTitle: {
        balance: "SELECT YOUR WALLET",
        bank_transfer: "PICK AN OPTION",
        credit_card: "SELECT A CARD"
      },
      IconWait,
      isSpinning: false
    };
  },
  computed: {
    ...mapGetters({
      disabledCreditcard: "disabledCreditcard",
      allowCreditcard: "allowCreditcard",
      allowAchPull: "allowAchPull",
      asset: "asset",
      allowPagsmile: "allowPagsmile"
    }),
    ...mapState([
      "defaultPaymentTabsActived",
      "defaultPaymentItemActived",
      "isSkipWireDisclaimer",
      "currencies"
    ]),
    getTabPane() {
      return this.getPaymentList(this.defaultPaymentTabsActived);
    },
    getListTitle() {
      return this.listTitle[this.defaultPaymentTabsActived] || "";
    },
    isCreditcardDataListEmpty() {
      const dataList = this.getPaymentList(this.defaultPaymentTabsActived);
      return (
        dataList.length === 0 &&
        this.defaultPaymentTabsActived === "credit_card"
      );
    },
    showAddCardBtn() {
      return (
        this.allowCreditcard &&
        this.defaultPaymentTabsActived === "credit_card" &&
        !this.isCreditcardDataListEmpty
      );
    },
    showMoreOrLess() {
      const filterPayment = this.getActivePayment(
        this.defaultPaymentTabsActived
      );
      return filterPayment?.showMoreOrLess ?? false;
    },
    getTxtMoreOrLess() {
      const filterPayment = this.getActivePayment(
        this.defaultPaymentTabsActived
      );
      return filterPayment?.txtMoreOrLess ?? "Show All";
    },
    showLinkAccountBtn() {
      // USD且currencies里面支持banktransfer
      return (
        this.allowAchPull &&
        this.defaultPaymentTabsActived === "bank_transfer" &&
        !this.isAchPullAccountDataListEmpty
      );
    },
    isAchPullAccountDataListEmpty() {
      const dataList = this.getPaymentList(this.defaultPaymentTabsActived);
      return (
        dataList.length === 0 &&
        this.defaultPaymentTabsActived === "bank_transfer"
      );
    },
    isKycProcess() {
      const { name } = this.$route;
      return name.startsWith("individual");
    },
    showUnLinkAccountBtn() {
      const dataList = this.getPaymentList(this.defaultPaymentTabsActived);
      return (
        dataList.length > 0 &&
        this.defaultPaymentTabsActived === "bank_transfer" &&
        this.allowAchPull
      );
    },
    showAddMoney() {
      return this.allowAchPull && this.defaultPaymentTabsActived === "balance";
    },
    currencyInfo() {
      return this.currencies.find(currency => currency.currency === this.asset);
    },
    dailyLimit() {
      if (this.defaultPaymentTabsActived === "balance") {
        return this.$t("Daily Bank Limit: Unlimited");
      } else if (this.defaultPaymentTabsActived === "credit_card") {
        const currencyInfo = this.currencies.find(
          currency => currency.currency === "USD"
        );

        const {
          daily_max_credit_card_limit,
          currency_symbol,
          decimals
        } = currencyInfo;
        const numberStr = daily_max_credit_card_limit.toString();

        const foramtLimit = formatNumber(numberStr, decimals);

        return this.$t(
          `Daily Card Limit: ${currency_symbol}${foramtLimit} remaining`
        );
      } else {
        return "";
      }
    }
  },
  components: {
    [Icon.name]: Icon,
    [Spin.name]: Spin,
    TradeTabs,
    TradeTabPane,
    TabPaneItem,
    IconArrow
  },
  watch: {},
  methods: {
    ...mapActions("DEPOSIT", ["updateWireCurrency4Deposit"]),
    ...mapActions([
      "updateSelectedBankTransferInfo",
      "updateDefaultPaymentTabsActived",
      "updateDefaultPaymentItemActived",
      "updateTradeBuyAchPullProcessIndex",
      "setTradeBuyAchPullProcesses",
      "updateTradeBuyAchPullInKycProcessIndex",
      "setTradeBuyAchPullInKycProcesses"
    ]),
    changeTabsItem(groupType) {
      if (this.defaultPaymentTabsActived === groupType) return; // 不要重复点击
      this.updateDefaultPaymentTabsActived(groupType);
      this.startQuote();
      // emitter.emit("legend-buy:startQuote");
    },
    getActivePayment(groupType) {
      return this.paymentList.find(item => item.groupType === groupType);
    },
    getPaymentList(groupType) {
      const filterPayment = this.getActivePayment(groupType);
      if (filterPayment?.txtMoreOrLess === "Show All") {
        return filterPayment?.list?.slice(0, 1) ?? [];
      } else {
        return filterPayment?.list ?? [];
      }
    },
    toAddCard() {
      const pathName = this.$route.name;
      if (pathName === "home.trade.buy" || pathName === "external.checkout") {
        this.$router.push({ name: "payment.card" });
      } else {
        this.$router.push({ name: "individual.payment.card" });
      }
    },
    changePaneItem(data) {
      if (data.checked) return; // 不要重复点击一个选中过的item
      if (this.isItemDisabled(data.type)) return;

      let groupType = "";
      if (["balance", "ach_balance"].includes(data.type)) {
        groupType = "balance";
      } else if (["bank_transfer", "cash_payment", "ach"].includes(data.type)) {
        groupType = "bank_transfer";
      } else {
        groupType = "credit_card";
      }

      const findIndex = this.paymentList.findIndex(
        item => item.groupType === groupType
      );
      const paymentList = this.getActivePayment(groupType).list.map(item => {
        return {
          ...item,
          checked: item?.payment_method_id === data?.payment_method_id
        };
      });

      if (groupType === "bank_transfer") {
        const selectPaymentInfo = paymentList.find(item => item.checked);
        this.updateSelectedBankTransferInfo(selectPaymentInfo);
      }
      this.paymentList[findIndex].list = paymentList;
      this.updateDefaultActived("payment_method_id", data.payment_method_id);
      this.updateDefaultActived("type", data.type);

      this.startQuote();
      // emitter.emit("legend-buy:startQuote");
    },
    handleMoreOrLess() {
      const filterPayment = this.getActivePayment(
        this.defaultPaymentTabsActived
      );
      const paymentItem = {
        ...filterPayment,
        txtMoreOrLess:
          filterPayment.txtMoreOrLess === "Show All" ? "Show Less" : "Show All"
      };
      const findIndex = this.paymentList.findIndex(
        item => item.groupType === this.defaultPaymentTabsActived
      );

      this.updateDefaultActived(
        "more",
        filterPayment.txtMoreOrLess === "Show All" ? true : false
      );
      this.$set(this.paymentList, findIndex, paymentItem);
    },
    updateDefaultActived(key, value) {
      let defaultPaymentItemActived = { ...this.defaultPaymentItemActived };
      defaultPaymentItemActived[this.defaultPaymentTabsActived][key] = value;

      this.updateDefaultPaymentItemActived({
        ...defaultPaymentItemActived
      });
    },
    async toLinkAccount() {
      if (this.isKycProcess) {
        this.linkAccountInKycProcess();
      } else {
        this.linkAccountInTradeProcess();
      }
    },
    async linkAccountInKycProcess() {
      if (this.isSpinning) return;
      this.isSpinning = true;

      try {
        this.updateTradeBuyAchPullInKycProcessIndex(0); // 初始化每次link之前都是第一步

        const result = await checkAchPullProcess({
          scene: "kyc_plaid_bind" //
        });
        if (result.success) {
          const { data } = result;
          this.setTradeBuyAchPullInKycProcesses(data);
          const pathName = this.getTradeBuyAchPullInKycProcessPathName();
          this.$router.push({
            name: pathName,
            params: { processType: "tradeInKyc" }
          });
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.isSpinning = false;
      }
    },
    async linkAccountInTradeProcess() {
      try {
        if (this.isSpinning) return;
        this.isSpinning = true;

        this.updateTradeBuyAchPullProcessIndex(0); // 初始化每次link之前都是第一步
        const result = await checkAchPullProcess();
        if (result.success) {
          const { data } = result;
          this.setTradeBuyAchPullProcesses(data);
          const pathName = this.getTradeBuyAchPullPathName();
          this.$router.push({
            name: pathName,
            params: { processType: "inTrade" }
          });
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      } finally {
        this.isSpinning = false;
      }
    },
    toPaymentMethodsList() {
      this.$router.push({ name: "account.paymentList" });
    },
    isItemDisabled(type) {
      return !this.isEnoughBalance && type === "balance";
    },
    showBalanceAddMoney(type) {
      // 没有足够的钱 && 不是只支持信用卡 && 不是小币种的  需要展示add money

      return (
        this.isItemDisabled(type) &&
        this.disabledCreditcard &&
        this.currencyInfo.legend_liquidity
      );
    },
    toDeposit() {
      this.updateWireCurrency4Deposit(this.asset);
      if (this.isKycProcess) {
        if (this.allowAchPull) {
          this.$router.push({ name: "individual.deposit" });
        } else {
          if (this.isSkipWireDisclaimer) {
            this.$router.push({
              name: "individual.wireTransfer.confirm"
            });
          } else {
            this.$router.push({ name: "individual.wireTransfer.notice" });
          }
        }
      } else {
        if (this.allowAchPull) {
          this.$router.push({ name: "deposit" });
        } else {
          if (this.isSkipWireDisclaimer) {
            this.$router.push({
              name: "deposit.wire.confirm"
            });
          } else {
            this.$router.push({ name: "deposit.wire.notice" });
          }
        }
      }
    }
  },

  created() {},
  mounted() {}
};
</script>

<style module lang="scss">
@import "~rfs/scss";

.tradePaymentListWrapper {
  @include rfs(520px, max-width);

  margin: 0 auto 30px auto;

  .dailyLimit {
    @include rfs(14px);
    @include rfs(10px, margin-top);
    @include rfs(30px, margin-bottom);

    font-weight: 500;
    color: var(--legend-c-text);
  }

  .description {
    @include rfs(14px);
    @include rfs(50px, margin-top);

    letter-spacing: -0.3px;
    color: var(--grey-cool);
    span {
      color: var(--primary);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .listHeader {
    @include rfs(20px, margin-bottom);

    position: relative;

    .title {
      @include rfs(14px);

      font-weight: 500;
      color: var(--grey-cool);
    }
    .addCard {
      @include rfs(14px);

      max-width: 10rem;
      position: absolute;
      right: 0;
      top: 0;
      font-weight: 600;
      color: var(--primary);
      cursor: pointer;
    }

    .addMoney {
      @include rfs(14px);

      max-width: 10rem;
      position: absolute;
      right: 0;
      top: 0;
      font-weight: 600;
      color: var(--primary);
      cursor: pointer;
    }

    .addBalanceMoney {
      @include rfs(14px);

      font-weight: 600;
      color: var(--legend-c-text);
      cursor: pointer;
    }

    .linkAccount {
      @include rfs(14px);

      max-width: 10rem;
      position: absolute;
      right: 0;
      top: 0;
      font-weight: 600;
      color: var(--primary);
      cursor: pointer;
    }
  }

  .creditCardEmpty {
    @include rfs(14px);
    @include rfs(70px, height);

    line-height: 70px;
    width: 100%;
    border-radius: rfs-value(20px);
    box-shadow: inset 0 0 0 1px var(--silver);
    font-weight: 600;
    color: var(--legend-c-text);
    cursor: pointer;
  }

  .moreOrLess {
    @include rfs(14px);
    @include rfs(20px, margin-bottom);

    font-weight: 500;
    color: var(--legend-c-text);
    cursor: pointer;

    span {
      @include rfs(12px, margin-right);
    }

    .iconArrowDown {
      @include rfs(0.875rem, max-width);
      color: var(--legend-c-text);
      transition: 0.2s;
      transform-origin: center;
      transform: rotate(0deg);
    }

    .iconArrowUp {
      @include rfs(0.875rem, max-width);
      color: var(--legend-c-text);
      transition: 0.2s;
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
  :global {
    .anticon-spin {
      @include rfs(2.5rem);
    }
  }
}
</style>
