import loadjs from "loadjs";

const CONFIG = {
  plaid: {
    baseUrl: "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
  },
  zendesk: {
    baseUrl: "https://static.zdassets.com/ekr/snippet.js",
    searchParams: {
      key: "965a118c-4fcb-4510-b492-14b361fc6799"
    },
    loadOptions: {
      before: function(path, scriptEl) {
        scriptEl.id = "ze-snippet";
      }
    }
  },
  googlePlaceAutocomplete: {
    baseUrl: "https://maps.googleapis.com/maps/api/js",
    searchParams: {
      key: "AIzaSyDh8SNezsOi0aIcq9qLvjHz7zWMP3zcmQg",
      libraries: "places"
    }
  },
  checkoutFramesV2: {
    baseUrl: "https://cdn.checkout.com/js/framesv2.min.js"
  },
  checkoutRisk: {
    baseUrl: "https://risk.checkout.com/cdn/risk/2.0/risk.js",
    loadOptions: {
      before: function(path, scriptEl) {
        scriptEl.integrity =
          "sha384-B78oU8xZwDp9vNwaFIGjHPsOXgLKonuEJXcbMKDAq9cPB+Zh545KxuVx4qlOki0V";
        scriptEl.crossOrigin = "anonymous";
      }
    }
  },
  sardine: {
    baseUrl: "https://api.sardine.ai/assets/loader.min.js"
  }
};

const CONFIG_SANDBOX = {
  checkoutRisk: {
    baseUrl: "https://risk.sandbox.checkout.com/cdn/risk/2.0/risk.js",
    loadOptions: {
      before: function(path, scriptEl) {
        scriptEl.integrity =
          "sha384-v4imG5YwivvIFd2uzkyW6Vfzu0neTHsfjUKJLUD8daa1QdKaIYy/Hp1czN1wK7oV";
        scriptEl.crossOrigin = "anonymous";
      }
    }
  },
  sardine: {
    baseUrl: "https://api.sandbox.sardine.ai/assets/loader.min.js"
  }
};

export const loadExternalLib = (lib, env = "") => {
  const isSandbox = ["dev", "sandbox", "demo", "demo-2023"].includes(env);
  const config = isSandbox ? CONFIG_SANDBOX : CONFIG;
  const { baseUrl, searchParams, loadOptions } = config[lib];
  const search = new URLSearchParams(searchParams);
  const url = search?.size === 0 ? baseUrl : `${baseUrl}?${search.toString()}`;

  return loadjs.isDefined(lib)
    ? new Promise((resolve, reject) =>
        loadjs.ready(lib, {
          success: resolve,
          error: reject
        })
      )
    : loadjs([url], lib, {
        returnPromise: true,
        ...loadOptions
      });
};

export const loadPlaid = () => loadExternalLib("plaid");

export const loadZendesk = () => loadExternalLib("zendesk");

export const loadPlaceAutocomplete = () =>
  loadExternalLib("googlePlaceAutocomplete");

export const loadCheckoutFramesV2 = () => loadExternalLib("checkoutFramesV2");

export const loadCheckoutRisk = env => loadExternalLib("checkoutRisk", env);

export const loadSardine = env => loadExternalLib("sardine", env);
