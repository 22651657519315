<template>
  <RegisterHomeLayout>
    <template #left>
      <RegisterDynamicStepList v-if="showLeftStepList" />
    </template>
    <template #default>
      <transition>
        <keep-alive>
          <router-view>
            <slot name="title" slot="title" />
          </router-view>
        </keep-alive>
      </transition>
    </template>
    <template #right>
      <RegisterOrderSummary v-if="showRightOrderSummary" />
    </template>
  </RegisterHomeLayout>
</template>

<script>
import RegisterHomeLayout from "@/components/register/HomeLayout.vue";
import RegisterOrderSummary from "@/components/register/OrderSummary.vue";
import RegisterDynamicStepList from "@/components/register/DynamicStepList.vue";

export default {
  name: "RegisterIndex",
  components: {
    RegisterHomeLayout,
    RegisterOrderSummary,
    RegisterDynamicStepList
  },
  data() {
    return {};
  },
  computed: {
    routerList() {
      return [
        "/register",
        "/register/",
        "/individual/enterEmail",
        "/individual/verifyEmail"
      ];
    },

    showRightOrderSummary() {
      const path = this.$route.path;
      return !this.routerList.includes(path);
    },
    showLeftStepList() {
      const path = this.$route.path;
      return !this.routerList.includes(path);
    }
  },
  watch: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss" module></style>
