<template>
  <main
    :class="[$style.main, isFaqView ? $style.mainBgColor : null]"
    :style="inlineMainStyle"
  >
    <banner-top />
    <header-menu v-if="showMenu" />
    <section
      :class="{
        [$style.container]: true
      }"
    >
      <div :class="$style.content">
        <half-circle-spinner
          v-if="isLoading"
          :color="color"
          :class="$style.spinner"
        />
        <main-content v-else />
      </div>
    </section>
    <support-payment-list v-if="showSupportPaymentList" />
    <debug-panel v-if="isDemo || isDev" />
    <v-page-guide v-model="showGuide" />
    <vac
      v-if="(isDemo || isDev) && token"
      ref="countdown"
      :class="$style.countdown"
      :autoStart="false"
      :left-time="expiresIn * 1000"
      @finish="onCountdownFinish"
    >
      <span slot="prev">session expires in: </span>
      <span slot="process" slot-scope="{ timeObj }">
        {{ timeObj.ceil.s }}
      </span>
      <span slot="finish">Expired!</span>
    </vac>

    <a-modal
      v-model="isModalVisible"
      :cancelText="$t('Cancel')"
      :okText="$t('Okay, keep me connected.')"
      :getContainer="() => $root.$el"
      :closable="false"
      @ok="handleOk"
      @cancel="handleCancel"
      :cancelButtonProps="{ props: { size: uiSize } }"
    >
      <h5 :class="$style.title" slot="title">
        <a-icon :class="$style.alarm" :component="AlarmClock" />
        {{ $t("Session Time-out") }}
      </h5>
      <p>{{ $t("session_messages[0]") }}</p>
    </a-modal>
    <!-- 所有使用updateBaseModalVisible来更新modal是否展示的。都是人脸识别相关的 -->
    <modal-base
      :visible.sync="baseModalVisible"
      @cancel="onCancel"
      @ok="onOk"
    />
  </main>
</template>

<script>
import "./bootstrap";
import moment from "moment";
import acceptLanguage from "accept-language";
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { Icon, Message, Modal, Notification } from "ant-design-vue";
import i18n from "./i18n";
import { sortSupportLocales } from "./i18n";
import store from "./store";
import router from "./router";
import DebugPanel from "@/components/DebugPanel.vue";
import VPageGuide from "@/components/VPageGuide.vue";
import MainContent from "@/components/MainContent.vue";
import BannerTop from "@/components/BannerTop.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import SupportPaymentList from "@/components/SupportPaymentList.vue";
import ModalBase from "@/components/modal/ModalBase.vue";
import AlertMessage from "@/plugin/AlertMessage/index.js";
import AlarmClock from "@/assets/img/alarm-clock.svg?inline";
import emitter from "@/utils/eventBus";
import {
  injectGTM,
  sizeList,
  displayErrors,
  getCurrencyUnitList
} from "@/utils";
import { getHsvColor, colorToHexString } from "@/utils/colorPalette";
import { loadZendesk, loadCheckoutRisk } from "@/utils/loadjs";
import {
  api as apiTrade,
  initMockAdapter as initTradeMockAdapter,
  getPairsInfo as getPairsTrade,
  authorize,
  getCurrencies as getCurrenciesTrade,
  LoadMockJs as TradeLoadMockJs,
  getBalance as getTradeBalance
} from "@/api";
import {
  api as apiKyc,
  initMockAdapter as initKycMockAdapter,
  getPairsInfo as getPairsKyc,
  getCurrencies as getCurrenciesKyc,
  LoadMockJs as KycLoadMockJs
} from "@/api/kyc";
import {
  api as apiPlaid,
  initMockAdapter as initPlaidMockAdapter,
  LoadMockJs as PlaidLoadMockJs
} from "@/api/plaid";
import {
  api as apiKycV2,
  initMockAdapter as initKycV2MockAdapter,
  LoadMockJs as KycV2LoadMockJs,
  checkProcess as kycV2CheckProcess
} from "@/api/v2/kyc";
import "@/vee-validate";

Vue.use(AlertMessage);

const injectWebFont = (href = "") => {
  const $link = document.createElement("link");
  $link.rel = "stylesheet";
  $link.href = href;
  document.head.appendChild($link);
};

export default {
  name: "LegendTrade",
  i18n,
  store,
  router,
  components: {
    [Icon.name]: Icon,
    [Modal.name]: Modal,
    DebugPanel,
    VPageGuide,
    MainContent,
    BannerTop,
    HeaderMenu,
    ModalBase,
    SupportPaymentList
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    remote: {
      type: String,
      default: ""
    },
    checkout: {
      type: String,
      default: ""
    },
    lang: {
      type: String,
      default: "en",
      validator: value => {
        // supported ISO 639 language codes
        // return i18n.availableLocales.includes(value.toLowerCase());
        const supportLocales = sortSupportLocales();
        acceptLanguage.languages(supportLocales);
        const clientLang = acceptLanguage.get(value);
        return supportLocales.includes(clientLang);
      }
    },
    langs: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: "support@legendtrading.com"
    },
    noLegend: {
      type: Boolean,
      default: false
    },
    noSell: {
      type: Boolean,
      default: false
    },
    noTrial: {
      type: Boolean,
      default: false
    },
    noLangs: {
      type: Boolean,
      default: false
    },
    uiSize: {
      type: String,
      default: "large",
      validator: value => sizeList.includes(value)
    },
    primaryColor: {
      type: String,
      default: ""
    },
    urlStyle: {
      type: String,
      default: ""
    },
    urlAssets: {
      type: String,
      default: ""
    },
    urlDeposit: {
      type: String,
      default: ""
    },
    timestamp: {
      type: String,
      default: "1587364131"
    },
    signature: {
      type: String,
      default: "ArFlDTZJHOYFUTG8NceXk8uDFyCD0WxT7iEuf2DvI1Y="
    },
    appId: {
      type: String,
      default: "Bitrue"
    },
    appUid: {
      type: String,
      default:
        "13212Bd1ZZ5VO98z8Gf/z39m6RjSxhdOV9nW+8+6j9j+ZV2PumX1KhoOqxM+Y9Uk++elE:OTg3NjVmZWRjYmE0MzIxMA=="
    },
    appUrl: {
      type: String,
      default: "https://www.bitrue.com/"
    },
    appKey: {
      type: String,
      default: "Aw4fHfWZwFFi9q2l"
    },
    appEmail: {
      type: String,
      default: ""
    },
    appPhone: {
      type: String,
      default: ""
    },
    prefersColorScheme: {
      type: String,
      default: "light",
      validator: value => ["dark", "light"].includes(value)
    },
    requestForQuotation: {
      type: String,
      default: ""
    },
    customRouteEntry: {
      type: String,
      default: ""
    },
    appState: {
      type: String,
      default: ""
    },
    paymentIntentId: {
      type: String,
      default: ""
    },
    externalOrderId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      $sardine: null,
      $risk: null,

      isLoading: false,
      showGuide: false,
      isModalVisible: false,
      AlarmClock
    };
  },
  computed: {
    ...mapState([
      "themeColor",
      "clientInfo",
      "token",
      "expiresIn",
      "kycStatus",
      "nextKycStep",
      "exchangeConfig",
      "bgThemeColor",
      "baseModalVisible",
      "exchangeName",
      "accountEmail",
      "user_preference",
      "kycProcessSteps"
    ]),
    ...mapState("APICONFIG", ["extraConfig", "environment"]),
    ...mapGetters([
      "isAccountDisabled",
      "isAccountEnabled",
      "asset",
      "baseUrlTrade",
      "allowedLangs",
      "isExternalCheckoutProcess"
    ]),
    ...mapGetters("APICONFIG", [
      "hoseName",
      "baseUrlTrade",
      "baseUrlKyc",
      "baseUrlPlaid",
      "baseRemote",
      "checkoutKey"
    ]),
    showPoweredBy() {
      return !["trade.index"].includes(this.$route.name);
    },
    showMenu() {
      return (
        ![
          "register",
          "register.type",
          "register.individual",
          "kyc",
          "survey",
          "maintain"
        ].includes(this.$route.name) &&
        !this.$route?.name?.startsWith("individual") &&
        !this.$route?.name?.startsWith("additional")
      );
    },
    showSupportPaymentList() {
      return (
        ["register", "register.individual"].includes(this.$route.name) ||
        this.$route?.name?.startsWith("individual")
      );
    },
    currentMode: {
      get() {
        return this.mode;
      },
      set(val) {
        this.updateConfigMode(val);
      }
    },
    currentRemote: {
      get() {
        return this.remote;
      },
      set(val) {
        this.updateConfigRemote(val);
      }
    },
    currentCheckout: {
      get() {
        return this.checkoutKey;
      },
      set(checkout) {
        this.updateConfigExtra({ checkout });
      }
    },
    isLocal() {
      return this.hoseName.test(window.location.hostname);
    },
    isDev() {
      return this.mode === "dev";
    },
    isSandbox() {
      return this.mode === "sandbox";
    },
    isDemo() {
      return this.mode === "demo";
    },
    isDemo2023() {
      return this.mode === "demo-2023";
    },
    color: {
      get() {
        return this.themeColor;
      },
      set(val) {
        this.setThemeColor(val);
      }
    },
    bgColor: {
      get() {
        return this.bgThemeColor;
      },
      set(val) {
        this.setBgThemeColor(val);
      }
    },
    validLang() {
      // TODO: fallback with navigator.languages

      return this.allowedLangs.includes(this.lang)
        ? this.lang
        : i18n.fallbackLocale;
    },
    inlineMainStyle() {
      const primaryColor = this.color;

      const mainStyleInfo = {
        "--primary": primaryColor,
        "--primary-1": getHsvColor(primaryColor, 1),
        "--primary-2": getHsvColor(primaryColor, 2),
        "--primary-3": getHsvColor(primaryColor, 3),
        "--primary-4": getHsvColor(primaryColor, 4),
        "--primary-5": getHsvColor(primaryColor, 5),
        "--primary-6": primaryColor,
        "--primary-7": getHsvColor(primaryColor, 7),
        "--primary-8": getHsvColor(primaryColor, 8),
        "--primary-9": getHsvColor(primaryColor, 9),
        "--primary-10": getHsvColor(primaryColor, 10)
      };

      const {
        bgColor,
        fontColor,
        panelBgColor,
        componentBgColor,
        disabledBgColor
      } = require(`@/scss/theme/${this.bgColor}.scss`);

      return {
        ...mainStyleInfo,
        "--legend-c-bg": bgColor,
        "--legend-c-text": fontColor,
        "--legend-panel-bg": panelBgColor,
        "--legend-component-bg": componentBgColor,
        "--legend-disabled-bg": disabledBgColor
      };
    },
    accountStatus() {
      const { kycStatus, nextKycStep } = this;

      return {
        kycStatus,
        nextKycStep
      };
    },
    isFaqView() {
      return this.$route?.name?.startsWith("faq");
    }
  },
  provide() {
    return {
      publishCheckoutRiskData: this.publishCheckoutRiskData
    };
  },
  watch: {
    expiresIn(val) {
      this.$nextTick(() => {
        const { countdown } = this.$refs;
        if (val > 0) {
          countdown?.startCountdown?.(true);
        } else {
          countdown?.stopCountdown?.();
        }
      });
    },
    token(val, oldVal) {
      if (this.isDemo && !oldVal && val) {
        this.showGuide = true;
      }
    }
  },
  methods: {
    ...mapActions([
      "toggleMaintenance",
      "updateKycStatus",
      "updateConnectStatus",
      "updateAccountEmail",
      "updateAccountType",
      "updateExpiresIn",
      "updateExpiresAt",
      "updateExchangeConfig",
      "updateClientInfo",
      "setThemeColor",
      "setToken",
      "setPairs",
      "updateUserPreference",
      "setCurrencies",
      "updateAsset",
      "updateTradePair",
      "setBgThemeColor",
      "updateNextKycStep",
      "updateBaseModalVisible",
      "setBalance",
      "updateExchangeName",
      "setRefreshToken",
      "updateTokenExpiresDate",
      "setupRfqParams",
      "updateTokenType",
      "updateIsHighRiskIp",
      "updateIpAddressInfo",
      "updateOriginalNextKycStep",
      "updateCurrencyUnitList",
      "updateForbiddenInfo",
      "updateUserInfo",
      "updateModalView",
      "updateAccountStatus",
      "setCheckKycProcessList"
    ]),
    ...mapActions("APICONFIG", [
      "updateConfigMode",
      "updateConfigEnv",
      "updateConfigRemote",
      "updateConfigExtra"
    ]),
    async setupEnv() {
      if (this.mode) this.currentMode = this.mode;
      if (this.remote) this.currentRemote = this.remote;

      if (process.env.NODE_ENV === "development") {
        // respect env config during develpoment
      } else if (this.isLocal || this.isDev || this.isDemo2023) {
        // sandbox not work during local build
        this.updateConfigEnv("development");

        apiTrade.defaults.baseURL = this.baseUrlTrade;
        apiKyc.defaults.baseURL = this.baseUrlKyc;
        apiPlaid.defaults.baseURL = this.baseUrlPlaid;
      } else if (this.isSandbox || this.isDemo) {
        this.updateConfigEnv("sandbox");

        apiTrade.defaults.baseURL = this.baseUrlTrade;
        apiKyc.defaults.baseURL = this.baseUrlKyc;
        apiPlaid.defaults.baseURL = this.baseUrlPlaid;
      }

      if (this.isLocal || this.remote === "mock") {
        // setup mock data
        const [tradeMock, tradeMockAdapter] = await TradeLoadMockJs();
        initTradeMockAdapter(apiTrade, tradeMock, tradeMockAdapter);
        const [kycMock, kycMockAdapter] = await KycLoadMockJs();
        initKycMockAdapter(apiKyc, kycMock, kycMockAdapter);
        const [plaidMockAdapter] = await PlaidLoadMockJs();
        initPlaidMockAdapter(apiPlaid, plaidMockAdapter);
        const [kycV2Mock, kycV2MockAdapter] = await KycV2LoadMockJs();
        initKycV2MockAdapter(apiKycV2, kycV2Mock, kycV2MockAdapter);
      }

      // setup checkout public key at last, as the env may be changed during setup
      if (this.checkout) this.currentCheckout = this.checkout;
    },
    async getSign() {
      const {
        access_token,
        expires_in,
        kyc_status,
        connect_status,
        account_email,
        account_type,
        exchange_config,
        user_preference,
        next_kyc_step = "questionnaire",
        refresh_token = "",
        token_type = "",
        expires_at = "",
        user_info = {
          edd: {},
          rfi: {}
        },
        account_status
      } = await authorize(this.clientInfo);

      if (kyc_status === "Refresh Needed") {
        this.updateModalView("RefreshNeededPending");
        this.updateBaseModalVisible(true);
      }

      if (account_status === "inactive") {
        this.updateAccountStatus(account_status);
        router.push({ name: "refuses", params: { error: "inactive_user" } });
        return;
      }

      const isRefusesed =
        exchange_config?.allowed_payment_methods?.length === 0 ?? false;
      if (isRefusesed) {
        const remoteConfigIpDisabled = user_preference?.ip_disabled ?? false;
        this.updateIsHighRiskIp(remoteConfigIpDisabled);

        const remoteConfigIpAddressInfo = user_preference?.ip_address_info ?? {
          country: "",
          state: ""
        };
        this.updateIpAddressInfo(remoteConfigIpAddressInfo);

        router.push({ name: "refuses" });
        return;
      }

      this.updateNextKycStep(next_kyc_step);
      this.updateKycStatus(kyc_status);
      this.updateConnectStatus(connect_status);

      if (next_kyc_step === "redo_verify_id") {
        this.updateOriginalNextKycStep(next_kyc_step); // 记录原始的nextKycStep数据,针对WEB-375
      }

      this.updateUserPreference(user_preference ?? { default_fiat: null });

      const remoteConfigNoSell = exchange_config?.no_sell;
      const localConfigNoSell = this.clientInfo.noSell;
      this.updateClientInfo({
        noSell: remoteConfigNoSell ?? localConfigNoSell
      });

      if (account_email) {
        this.updateAccountEmail(account_email);
      }
      this.updateAccountType(account_type?.toLowerCase() ?? "individual");
      this.setToken(access_token);
      this.setRefreshToken(refresh_token);

      this.updateExpiresIn(expires_in);
      this.updateExpiresAt(expires_at);
      this.updateTokenType(token_type);
      if (expires_in) {
        this.updateTokenExpiresDate(
          moment()
            .add(expires_in ?? 0, "seconds")
            .format()
        );
      }

      if (token_type === "Bearer") {
        // get sardine session after authorize
        // emitter.emit("legend-sardine:load", "authorize");
        // load checkout risk script
        // emitter.emit("legend-checkout:load-risk");
      }

      this.updateExchangeName(exchange_config?.exchange_name ?? "");
      this.updateExchangeConfig(exchange_config);
      this.updateUserInfo(user_info);

      this.navigateToByStatus(kyc_status, connect_status);
      // this.navigateByStatus(kyc_status, routeName);
    },
    async getAuth() {
      try {
        this.isLoading = true;
        if (
          process.env.NODE_ENV !== "development" &&
          this.isDemo &&
          this.isAccountEnabled &&
          !this.isDev
        ) {
          this.setToken(process.env.VUE_APP_DEMO_TOKEN);
          this.updateExpiresIn(1800);
        } else {
          await this.getSign();
        }

        // TOOD: error handler of Promise.all
        const [pairs, currencies, balance] = await Promise.all([
          this.getPairs(),
          this.getCurrency(),
          this.getBalance()
        ]);

        const currencyUnitList = getCurrencyUnitList(currencies);

        this.setPairs(pairs);
        this.setCurrencies(currencies);
        this.updateCurrencyUnitList(currencyUnitList);
        this.setBalance(balance);

        const currencyInfo = currencies.find(
          item => item.currency === this.user_preference?.default_fiat
        );

        let defaultAsset = "";

        if (!currencyInfo) {
          // 没有找到，使用currencies的第一个数据做为默认的法币
          defaultAsset = currencies[0].currency;
          this.updateAsset(defaultAsset);
        } else {
          // 找到了就用当前的数据做为默认法币
          defaultAsset = currencyInfo.currency;
          this.updateAsset(defaultAsset);
        }

        const pairsList = this.handlePairsData(pairs, defaultAsset);
        const currentPair = pairsList[0];
        this.updateTradePair(currentPair);
      } catch (err) {
        if (err.response) {
          const { code, maintenance_mode } = err.response.data;
          if (code === "10401") {
            // account email occupied
            this.showGuide = false;
            router.push({ name: "refuses", params: { error: "email" } });
          } else if (maintenance_mode) {
            this.toggleMaintenance(true);
            this.showGuide = false;
            router.push("/maintain");
          }
        }
        displayErrors(err);

        this.setBalance({
          crypto_exchange: {},
          crypto: {},
          fiat: {}
        });
      } finally {
        this.setupRfqParams();
        this.isLoading = false;
      }
    },
    getPairs() {
      return this.isAccountEnabled ? getPairsTrade() : getPairsKyc();
    },
    getCurrency() {
      return this.isAccountEnabled ? getCurrenciesTrade() : getCurrenciesKyc();
    },
    getBalance() {
      return this.isAccountEnabled && getTradeBalance();
    },
    injectStyle(url) {
      const $link = document.createElement("link");
      $link.rel = "stylesheet";
      $link.href = url;
      const $rootnode = this.$el.getRootNode();
      if ($rootnode instanceof window.ShadowRoot) {
        // shadow dom
        $rootnode.insertBefore($link, this.$el);
      } else {
        // html
        document.head.appendChild($link);
      }
    },
    async loadSardine() {
      // eslint-disable-next-line
      console.log("Skip loading of sardine, no longer using.");
      return this.$sardine;
    },
    async loadCheckoutRisk() {
      if (this.$risk) return this.$risk;

      await loadCheckoutRisk(this.mode);
      const risk = window.Risk.init(this.currentCheckout);
      this.$risk = risk;
      return risk;
    },
    async publishCheckoutRiskData() {
      try {
        // make sure checkout risk is loaded
        await this.loadCheckoutRisk();

        const deviceSessionId = await this.$risk?.publishRiskData?.();
        // console.log("deviceSessionId: ", deviceSessionId);
        return deviceSessionId;
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
        return "";
      }
    },
    onCountdownFinish() {
      // this.isModalVisible = true;
    },
    handleOk() {
      window.location.reload();
    },
    handleCancel() {
      window.close();
    },
    onCancel() {
      this.updateBaseModalVisible(false);
    },
    onOk() {
      this.updateBaseModalVisible(false);
    },

    handlePairsData(pairs, defaultAsset) {
      // 先找设定的法币对应的交易对，找到就正常展示
      const set = new Set();
      const defaultSet = new Set();
      pairs.forEach(itemPair => {
        Object.keys(itemPair).forEach(pair => {
          let v = itemPair[pair];
          const vArr = v?.display?.split(" / ") ?? "";
          if (defaultAsset === vArr[1]) {
            vArr.length && set.add(vArr[0]);
          } else {
            vArr.length && defaultSet.add(vArr[0]);
          }
        });
      });
      const arr = Array.from(!set.size ? defaultSet : set);
      return arr;
    },
    navigateToByStatus(kycStatus, connectStatus) {
      const { name, path } = this.$route;
      const { customRouteEntry } = this;
      if (name === "refuses") return;
      if (connectStatus === "Pending") {
        // redirect to pending entry for kyc connect
        const pending = "register.pending";
        name !== pending && router.push({ name: pending });
      } else if (connectStatus === "QA") {
        const kycSurvey = "individual.kycSurvey";
        name !== kycSurvey && router.push({ name: kycSurvey });
      } else {
        if (customRouteEntry) {
          if (kycStatus === "Approved" && this.blackList(customRouteEntry)) {
            path !== customRouteEntry && router.push(customRouteEntry);
          } else if (
            ["Not Started", "Started", "In Progress"].includes(kycStatus) &&
            customRouteEntry === "/kyc"
          ) {
            path !== customRouteEntry && router.push(customRouteEntry);
          } else {
            this.navigateByStatus(kycStatus, name);
          }
        } else {
          this.navigateByStatus(kycStatus, name);
        }
      }
    },
    blackList(path) {
      return (
        ![
          "/register",
          "register.individual",
          "/register/accountType",
          "/kyc",
          "/plaid",
          "/applyDone",
          "/maintain"
        ].includes(path) &&
        !path?.startsWith("/individual") &&
        !path?.startsWith("/additional")
      );
    },
    navigateByStatus(kycStatus, name) {
      switch (kycStatus) {
        case "Not Started":
        case "Started":
        case "In Progress": {
          // !name?.startsWith("register") && router.push({ name: "register" });
          this.getCheckProcessList();
          !name?.startsWith("register") && router.push({ name: "register" });
          break;
        }
        case "Submitted":
        case "In Review":
        case "Rejected": {
          if (this.isExternalCheckoutProcess) {
            !name?.startsWith("individual.verifyFailed") &&
              router.push({ name: "individual.verifyFailed" });
          } else {
            !name?.startsWith("home") && router.push({ name: "home.trade" });
          }
          break;
        }
        case "Approved": {
          if (this.isExternalCheckoutProcess) {
            !name?.startsWith("external.checkout") &&
              router.push({ name: "external.checkout" });
          } else {
            !name?.startsWith("home") && router.push({ name: "home.trade" });
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    async getCheckProcessList() {
      try {
        const result = await kycV2CheckProcess();
        if (result.status === "success") {
          const { fields } = result.data[0];
          const checkProcessList = fields;
          this.setCheckKycProcessList(checkProcessList);
        } else {
          displayErrors(result);
        }
      } catch (err) {
        displayErrors(err);
      }
    }
  },
  beforeCreate() {
    injectWebFont(
      "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"
    );
  },
  created() {
    // setup emitter
    emitter.on("legend-error:forbidden", data => {
      this.updateForbiddenInfo(data); // 为什么当时要存到store里面，不直接把参数传递到路由里面呢？
      router.push({ name: "forbidden" });
    });
    emitter.on("legend-sardine:load", async flow => {
      const sardine = await this.loadSardine({ flow });
      // eslint-disable-next-line
      console.log("load sardine: ", flow, sardine);
    });
    emitter.on("legend-checkout:load-risk", async () => {
      const risk = await this.loadCheckoutRisk();
      // eslint-disable-next-line
      console.log("load checkout risk: ", risk);
    });
    emitter.on("legend-error:rejected", () => {
      this.updateModalView("RejectedAccount");
      this.updateBaseModalVisible(true);
    });
    emitter.on("legend-error:restriction", data => {
      router.push({ name: "restriction", params: data });
    });

    emitter.on("*", (type, e) => {
      if (type.startsWith("legend:")) {
        // eslint-disable-next-line
        console.info(`custom event dispatched: [${type}]`);
        if (Array.isArray(e)) {
          this.$emit(type, ...e);
        } else {
          this.$emit(type, e);
        }
      }
    });

    // put app props into store
    this.updateClientInfo(this.$props);

    // this.updateClientInfo({ ...this.$props, ...this.clientInfo });
    this.$i18n.locale = this.validLang;

    // 主题色
    if (this.primaryColor) {
      try {
        this.color = colorToHexString(this.primaryColor);
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    }
    // 背景色
    if (this.prefersColorScheme) {
      try {
        this.bgColor = this.prefersColorScheme;
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    }
    this.setupEnv();
    this.getAuth();
  },
  mounted() {
    if (this.urlStyle) {
      this.$nextTick(() => {
        this.injectStyle(this.urlStyle);
      });
    }

    this.$nextTick(() => {
      const vueTelInputStyleUrl =
        "https://fastly.jsdelivr.net/npm/vue-tel-input@5.10.1/dist/vue-tel-input.css";
      this.injectStyle(vueTelInputStyleUrl);
      const vueFlagIconStyleUrl =
        "https://fastly.jsdelivr.net/npm/flag-icons@6.1.1/css/flag-icons.min.css";
      this.injectStyle(vueFlagIconStyleUrl);
      const viewerjsStyleUrl =
        "https://cdn.jsdelivr.net/npm/viewerjs@1.11.6/dist/viewer.min.css";
      this.injectStyle(viewerjsStyleUrl);
    });
    // load zendesk
    window.zESettings = {
      webWidget: {
        position: { horizontal: "right", vertical: "bottom" },
        color: { theme: colorToHexString(this.color) },
        contactForm: {
          ticketForms: [{ id: 1260815209589 }]
        }
      }
    };
    loadZendesk().then(() => {
      window.zE("webWidget", "hide");
    });

    Message.config({
      getContainer: () => this.$root.$el
    });
    Notification.config({
      getContainer: () => this.$root.$el,
      placement: "bottomRight"
    });

    AlertMessage.config({ getContainer: () => this.$root.$el });
    // inject google tagmanager
    injectGTM("jssdk-v3");
  },
  beforeDestroy() {
    emitter.all.clear();
  }
};
</script>

<!-- 覆盖antd的主题颜色 -->
<style lang="less">
@import "~ant-design-vue/dist/antd.less";
@import "./patch/less-functions-overrides.less";
@import "./theme.less";
</style>

<!-- 一些自定义的全局的样式 -->
<style lang="scss" src="./scss/host.scss" />

<style lang="scss" module>
@import "~rfs/scss";

.main {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  min-height: 100vh;
  overflow: visible;
  position: relative;
  .spinner {
    margin: calc(50vh - 4rem) auto;
  }

  a {
    text-decoration: none;
    color: var(--primary);
    transition: color 500ms ease;

    &:hover,
    &:global(.router-link-active),
    &:global(.router-link-exact-active) {
      color: var(--primary-5);
    }
    &:active {
      color: var(--primary-7);
    }
  }

  .container {
    width: 100%;
    margin: 0 auto;

    min-height: 100vh;
    .content {
      width: 100%;
      max-width: var(--max-width);
      margin: 0 auto;
      // position: relative;
    }
  }

  .footer {
    @include rfs(0.8rem);
    @include margin-top(2rem);
    @include padding(0.5rem 0);

    display: flex;
    border-top: 1px solid var(--primary-1);
    color: var(--secondary);
    justify-content: space-between;

    &.center {
      justify-content: center;
    }
    & > span > a {
      @include margin-left(0.5rem);
    }
  }

  .countdown {
    left: 0;
    position: fixed;
    bottom: 0;
  }

  .title {
    @include rfs(1.25rem);

    align-items: center;
    color: var(--primary);
    display: flex;
    font-weight: 500;
    justify-content: center;
    line-height: 1.2;
    margin: 0;
    text-align: center;

    .alarm {
      @include rfs(1.5rem);
      @include margin(0 0.5rem);

      color: var(--success);
    }
  }
}

.mainBgColor {
  background-image: linear-gradient(
    323deg,
    var(--primary-a20),
    var(--primary-2)
  );
}

.dark,
.light {
  background: var(--legend-c-bg);
}
</style>
