const routes = [
  {
    path: "",
    name: "additional.individual.personalInfo",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/PersonalInfo.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      title: "BASIC INFORMATION",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/addressInfo",
    name: "additional.individual.addressInfo",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/AddressInfo.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      title: "ENTER YOUR PRIMARY ADDRESS",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/poa",
    name: "additional.individual.poa",
    meta: {
      type: "additional_individual_kyc",
      title: "UPLOAD PROF OF ADDRESS",
      showBackBtn: false
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../../views/register/individual/Poa.vue"
      )
  },
  {
    path: "/additional/individual/phoneInfo",
    name: "additional.individual.phoneInfo",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/PhoneInfo.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      title: "Mobile Phone",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/verifyPhone",
    name: "additional.individual.verifyPhone",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/VerifyPhone.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      title: "Mobile Phone",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/kycSurvey",
    name: "additional.individual.kycSurvey",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/KycSurvey.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      title: "QUESTIONNAIRE",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/verifyIdentify",
    name: "additional.individual.verifyIdentify",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/VerifyIdentify.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      title: "VERIFY IDENTITY",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/autoVerify",
    name: "additional.individual.autoVerify",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/AutoVerify.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      title: "VERIFY YOUR IDENTITY",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/verifySuccess",
    name: "additional.individual.verifySuccess",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/VerifySuccess.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/verifyFailed",
    name: "additional.individual.verifyFailed",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/VerifyFailed.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/enterEmail",
    name: "additional.individual.enterEmail",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/EnterEmail.vue"
      ),
    meta: {
      type: "additional_individual_kyc",
      showBackBtn: false
    }
  },
  {
    path: "/additional/individual/verifyEmail",
    name: "additional.individual.verifyEmail",
    component: () =>
      import(
        /* webpackChunkName: "additionalKyc" */ "../../views/additionalKyc/individual/VerifyEmail.vue"
      ),
    meta: {
      type: "additional_individual_kyc"
    }
  }
];

export default routes;
