<template>
  <section :class="$style.registerWrapper">
    <LocalesSelector
      v-if="!noLangs"
      :class="$style.localesWrapper"
      :localesList="localesList"
    />
    <section :class="$style.registerContainer">
      <slot name="left" />
      <section :class="$style.content">
        <slot name="default" />
      </section>
      <slot name="right" />
      <powered-by :class="$style.poweredBy" />
      <need-help :class="$style.needHelp" />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PAYMENT from "@/store/payment/index";
import REGISTER from "@/store/register/index";
import DEPOSIT from "@/store/deposit/index";
import KYC from "@/store/kyc/index";
import store from "@/store/index";
import PoweredBy from "@/components/PoweredBy.vue";
import NeedHelp from "@/components/NeedHelp.vue";
import LocalesSelector from "@/components/LocalesSelector.vue";

export default {
  name: "RegisterIndex",
  components: {
    PoweredBy,
    NeedHelp,
    LocalesSelector
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["allowedLangs"]),
    ...mapState({
      noLangs: state => state.clientInfo.noLangs
    }),
    localesList() {
      return this.allowedLangs;
    }
  },
  watch: {},
  methods: {},
  created() {
    if (!store.hasModule("PAYMENT")) {
      store.registerModule("PAYMENT", PAYMENT);
    }

    if (!store.hasModule("REGISTER")) {
      store.registerModule("REGISTER", REGISTER);
    }

    if (!store.hasModule("DEPOSIT")) {
      store.registerModule("DEPOSIT", DEPOSIT);
    }

    if (!store.hasModule("KYC")) {
      store.registerModule("KYC", KYC);
    }
  }
};
</script>

<style lang="scss" module>
@import "~rfs/scss";

.registerWrapper {
  .localesWrapper {
    @include rfs(5.3125rem, height);
    @include rfs(10rem, padding-right);

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .registerContainer {
    @include rfs(3.75rem 0rem 0rem 0rem, padding);
    position: relative;

    .stepList {
      position: absolute;
      left: 4.375rem;
      top: 6.25rem;

      .stepTitle {
        @include rfs(1.5rem);
        @include rfs(2.5rem, margin-bottom);

        color: var(--legend-c-text);
        span {
          &:first-of-type {
            @include rfs(2rem);
            font-weight: bold;
            color: var(--legend-c-text);
          }

          &:last-of-type {
            color: var(--primary);
          }
        }
      }

      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        li {
          @include rfs(1rem, margin-bottom);

          display: flex;
          align-items: center;
          counter-increment: number; /* ① 有序数字*/
          color: var(--legend-c-text);

          &::before {
            @include rfs(1.25rem, margin-right);
            @include rfs(1.5rem, width);
            @include rfs(1.5rem, height);
            @include rfs(0.875rem);

            font-weight: bold;
            border-radius: 50%;
            background-color: var(--primary);
            content: counter(number);
            text-align: center;
            line-height: 1.5rem;
            color: var(--legend-c-bg);
          }
        }
      }
    }

    .content {
      @include rfs(39.375rem, min-width);
      @include rfs(600px, min-height);
      @include rfs(39.375rem, width);
      @include rfs(2.1875rem 2.1875rem 2.5rem 2.1875rem, padding);

      margin: 0 auto;
      border-radius: 0.625rem;
      box-shadow: 0 0.375rem 1.125rem 0 var(--dark-70-5);
      background: var(--legend-panel-bg);
      text-align: center;
    }

    .poweredBy {
      @include rfs(1rem, margin-top);

      justify-content: center;
    }

    .needHelp {
      @include rfs(4.375rem, padding-left);

      svg {
        color: var(--grey-cool);
      }

      & > h6 {
        color: var(--grey-cool);
      }
    }

    // KYC 流程中 用的antd组件，全局覆盖
    :global {
      .errorMsg {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          @include rfs(1rem);
          @include rfs(0.375rem, margin-right);
          @include rfs(1rem, width);
          @include rfs(1rem, min-width);

          color: var(--danger);
        }

        span {
          color: var(--danger);
          font-weight: bold;
          text-align: left;
        }
      }

      .formValidation {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .ant-col-24 {
        text-align: left;
      }

      .ant-col > p {
        @include rfs(1rem);
        @include rfs(0.9375rem, margin-bottom);

        text-align: left;
        font-weight: 500;
        color: var(--legend-c-text);
      }

      .ant-row-flex {
        .ant-col-12:first-child {
          @include rfs(0.625rem, padding-right);
        }
        .ant-col-12:last-child {
          @include rfs(0.625rem, padding-lef);
        }
      }

      .ant-form {
        width: 100%;
      }

      .ant-form-item {
        @include rfs(0.9375rem, margin-bottom);
      }

      .has-error .ant-form-explain {
        @include rfs(0.875rem);
        @include rfs(0.3125rem, margin-top);

        color: var(--danger);
        font-weight: bold;
        text-align: left;
      }

      .has-success .ant-form-explain {
        @include rfs(0.875rem);
        @include rfs(0.3125rem, margin-top);

        color: var(--danger);
        font-weight: bold;
        text-align: left;
      }

      .ant-form-item-children {
        display: block;
      }

      .ant-input {
        @include rfs(1.5625rem, padding-left);
        @include rfs(1.25rem);

        height: 3.75rem;

        border-radius: 0.625rem;
        color: var(--legend-c-text);
        background-color: var(--legend-component-bg);
      }

      .has-error .ant-input {
        background-color: var(--legend-component-bg);
      }

      .has-error .ant-input:hover {
        background-color: var(--legend-component-bg);
      }

      .ant-input::-webkit-input-placeholder {
        @include rfs(1.25rem);

        color: var(--silver);
      }

      .ant-input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        @include rfs(1.25rem);

        color: var(--silver);
      }

      .ant-input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        @include rfs(1.25rem);

        color: var(--silver);
      }

      .ant-input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        @include rfs(1.25rem);

        color: var(--silver);
      }

      .ant-input-lg {
        height: 3.75rem;
      }

      .ant-calendar-picker {
        width: 100%;

        .anticon {
          @include rfs(1.25rem, width);
          @include rfs(1.25rem, height);

          top: 45%;
        }

        svg {
          @include rfs(1.25rem, width);
          @include rfs(1.25rem, height);

          top: 45%;
        }
      }

      .ant-select-selection {
        color: var(--legend-c-text);
        background-color: var(--legend-component-bg);
      }

      .ant-select-lg .ant-select-selection--single,
      .ant-select-lg .ant-select-selection--multiple {
        @include rfs(1.25rem);
        @include rfs(3.75rem, min-height);

        .ant-select-selection__rendered {
          @include rfs(1.5625rem, margin-left);

          line-height: 3.75rem;

          li {
            line-height: 3.75rem;
          }
        }

        .ant-select-arrow {
          top: 45%;
        }

        .ant-select-selection__clear {
          @include rfs(1.25rem, width);
          @include rfs(1.25rem, height);

          top: 45%;
        }

        .ant-select-selection__choice {
          @include rfs(3.75rem, min-height);

          line-height: rfs-value(3.75rem);
          color: var(--legend-c-text);
          background-color: var(--legend-component-bg);

          .ant-select-selection__choice__remove {
            top: rfs-value(4px);
          }
        }

        svg {
          @include rfs(1.25rem, width);
          @include rfs(1.25rem, height);

          top: 35%;
        }
      }

      .vue-tel-input {
        @include rfs(3.75rem, height);
        @include rfs(26.25rem, width);

        margin: auto;
        display: flex;
        align-items: center;
        border: solid 0.0625rem var(--silver);
        border-radius: 0.625rem;

        .vti__dropdown {
          @include rfs(1.3125rem);

          height: 3.625rem;
          background-color: var(--legend-component-bg);
          border-top-left-radius: 0.625rem;
          border-bottom-left-radius: 0.625rem;
        }

        .vti__country-code {
          @include rfs(1.25rem);
          @include rfs(0 0.25rem, margin);
          color: var(--legend-c-text);
        }

        .vti__dropdown-arrow {
          color: $legend-theme-color;
        }

        .vti__dropdown-list.above {
          bottom: 4rem;
        }

        .vti__dropdown-list.below {
          top: 4rem;
        }

        .vti__dropdown-list {
          border-radius: 0.625rem;

          .vti__dropdown-item {
            background-color: var(--legend-component-bg);
            color: var(--legend-c-text);
            &:first-of-type {
              @include rfs(2.5rem, margin-top);
            }
            @include rfs(0.3125rem 0.9375rem, padding);
          }

          /* 滚动条整体部分 */
          &::-webkit-scrollbar {
            @include rfs(0.1875rem, width);
            @include rfs(1.1875rem, height);

            border-radius: 0.625rem;
            background-color: var(--silver);
          }
          /* scroll轨道背景 */
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);

            border-radius: 0.625rem;
            background-color: var(--white);
          }

          /* 滚动条中能上下移动的小块 */
          &::-webkit-scrollbar-thumb {
            border-radius: 0.625rem;
            -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
            background-color: var(--silver);
          }

          &::-webkit-scrollbar-button {
            // background-color: #7c2929;
          } /* 滑轨两头的监听按钮颜色 */
        }

        .vti__input {
          @include rfs(1.25rem);
          @include rfs(3.625rem, height);
          @include rfs(1.5625rem, padding-left);

          border: none;
          border-radius: 0rem 0.625rem 0.625rem 0rem;
          border-left: solid 0.0625rem var(--silver);
          box-shadow: none;
          background-color: var(--legend-component-bg);
          color: var(--legend-c-text);
        }

        .vti__search_box {
          @include rfs(1.25rem);
          @include rfs(2.5rem, height);
          @include rfs(100%, width);

          color: var(--legend-c-text);
          background: var(--legend-component-bg);
          border-radius: 0;
          border-bottom: solid 0.0625rem var(--line);
          border-left: none;
          position: absolute;
          border-top-left-radius: 0.625rem;
          border-top-right-radius: 0.625rem;
          margin: 0;
        }
      }
    }
  }
}
</style>
