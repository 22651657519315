import { of } from "rxjs";
import { mergeMap, delay, repeat, tap } from "rxjs/operators";
import { mapGetters, mapState, mapActions } from "vuex";
import { rfqByFiat as rfqByFiatKyc } from "@/api/kyc";
import { queryQuoteForFiat as rfqByFiatTrade } from "@/api";
import { displayErrors } from "@/utils";

export const quoteMixin = {
  data() {
    return {
      source: null,
      timeInterval: 15000
    };
  },
  computed: {
    ...mapGetters(["isAccountEnabled", "asset"]),
    ...mapState([
      "tradePair",
      "tradeSide",
      "tradeQuantity",
      "defaultPaymentTabsActived",
      "defaultPaymentItemActived",
      "quoteSubscribe"
    ]),
    ...mapState({
      defaultQuoteType: state => state.clientInfo.defaultQuoteType
    }),
    getDefaultPaymentItemInfo() {
      return this.defaultPaymentItemActived[this.defaultPaymentTabsActived];
    },
    isAchPullItemSelected() {
      const defaultPaymentItemInfo = this.getDefaultPaymentItemInfo;
      const { type } = defaultPaymentItemInfo;
      if (["ach", "ach_balance"].includes(type)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions([
      "updateApproxInfo",
      "updateQuoteLoadTimer",
      "updateQuoteSubscribe",
      "updateQuoteErrorMessage",
      "updateQuoteParams"
    ]),
    polling() {
      this.source = of({}).pipe(
        mergeMap(() => this.handleQuote()),
        tap({ next: this.onNext.bind(this) }),
        delay(this.timeInterval),
        repeat()
      );
      const subscription = this.source.subscribe();
      this.updateQuoteSubscribe(subscription);
    },
    cleanup() {
      this.quoteSubscribe?.unsubscribe?.();
      this.updateQuoteSubscribe(null);
    },
    onNext(result = {}) {
      if (!result?.errors) {
        this.updateApproxInfo(result);
      } else {
        displayErrors(result);
      }
    },
    async handleQuote() {
      try {
        this.updateQuoteLoadTimer(true);

        const params = this.handleParams();
        return this.isAccountEnabled
          ? await rfqByFiatTrade(params)
          : await rfqByFiatKyc(params);
      } catch (err) {
        const { response } = err;
        const errors = Object.values(response?.data?.errors);
        const errorMessage = errors?.[0]?.[0] ?? "";
        this.updateQuoteErrorMessage(errorMessage);
        displayErrors(err);
      } finally {
        this.updateQuoteLoadTimer(false);
      }
    },
    handleParams() {
      const pair = `${this.tradePair}${this.asset}`;
      const side = this?.tradeSide?.toLowerCase() ?? "buy";
      const size = Number.parseFloat(this.tradeQuantity);
      const { payment_method_id, type } = this.getDefaultPaymentItemInfo;

      let paymentType = "";

      if (this.isAccountEnabled) {
        paymentType =
          type ||
          this.defaultPaymentTabsActived ||
          this.defaultQuoteType ||
          "balance";
      } else {
        paymentType = type || this.defaultQuoteType || "balance";
      }

      let params = {
        pair,
        side,
        size,
        payment_method_id: payment_method_id,
        type: paymentType
      };

      // if (this.isAchPullItemSelected) {
      //   params = { ...params, type: "ach" };
      // } else {
      //   params = { ...params, type: undefined };
      // }

      this.updateQuoteParams(params);
      return params;
    },
    startQuote() {
      // 这里将subscription(this.source.subscribe();的返回值),存到vuex中
      // 要不然在不同组件中导入mixin,并使用这个方法，就是不同的实例。
      if (this.quoteSubscribe !== null) this.stopQuote();
      this.polling();
    },
    stopQuote() {
      this.cleanup();
    }
  }
};
