<template>
  <section>
    <EnterEmail />
  </section>
</template>

<script>
import EnterEmail from "@/components/register/individual/EnterEmail.vue";

export default {
  name: "Register.Individual.EnterEmail",
  components: {
    EnterEmail
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  activated() {},
  deactivated() {}
};
</script>

<style lang="scss" module></style>
